<template>
  <div class="container">
    <PublicHeader :totalName="$t('authentication').title" />
    <van-form ref="refForm">
      <van-field v-model="form.RealName" :label="$t('authentication').RealName"
        :placeholder="$t('authentication').placeholder" />
      <!-- <van-field
      v-model="form.PhoneNumber"
      :label="$t('authentication').Mobile"
      :placeholder="$t('authentication').placeholder1"
      @input="form.PhoneNumber = form.PhoneNumber.replace(/[^\d]/g, '')"
    /> -->
      <van-field v-model="CardTypeName" :label="$t('authentication').CardType" :placeholder="$t('public').select"
        readonly right-icon="wap-nav" @click-right-icon="CardTypeShow = true" @click="CardTypeShow = true" />
      <van-field v-model="form.IdCard" :label="$t('authentication').IDNumber"
        :placeholder="$t('authentication').placeholder2" />
      <van-field v-model="form.IdCardAddress" :label="$t('authentication').CertificateAddress"
        :placeholder="$t('authentication').placeholder3" />
      <van-field v-model="form.Residence" :label="$t('authentication').CurrentAddress"
        :placeholder="$t('authentication').placeholder4" />
      <div class="authTxt">{{ $t('authentication').CertificateZ }}</div>
      <van-uploader class="img-box" preview-size="120" :preview-image="false" :after-read="justAfterRead"
        :disabled="IsReal">
        <img class="upImage" v-if="ObverseImage" :src="ObverseImage" alt="" />
      </van-uploader>
      <div class="authTxt">{{ $t('authentication').CertificateF }}</div>
      <van-uploader class="img-box" preview-size="120" :preview-image="false" :after-read="justAfterRead1"
        :disabled="IsReal">
        <img class="upImage" v-if="ReverseImage" :src="ReverseImage" alt="" />
      </van-uploader>
      <div v-if="!IsReal">
        <p>{{ $t('authentication').warnInfo }}</p>
        <div class="bigBtn" @click="handleSubmit">
          {{ $t('authentication').Confirm }}
        </div>
      </div>
    </van-form>
    <van-popup v-model="CardTypeShow" position="bottom">
      <van-picker :title="$t('public').select" :confirm-button-text="$t('public').confirm"
        :cancel-button-text="$t('public').cancel" show-toolbar :default-index="defaultIndex" value-key="Name"
        :columns="CardTypeArr" @confirm="onConfirm" @cancel="CardTypeShow = false" />
    </van-popup>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      CardTypeShow: false,
      CardTypeName: this.$t('authentication').IDCard,
      CardTypeArr: [{ Id: 'IDCard', Name: this.$t('authentication').IDCard }, { Id: 'License', Name: this.$t('authentication').License }, { Id: 'Passport', Name: this.$t('authentication').Passport }],
      defaultIndex: 0,
      IsReal: true,
      form: {
        RealName: '',
        PhoneNumber: '',
        CardType: 'IDCard',
        IdCard: '',
        IdCardAddress: '',
        Residence: '',
        IdCardImg: ''
      },
      ObverseImage: '',
      ReverseImage: '',
    }
  },
  methods: {
    onConfirm(value) {
      this.form.CardType = value.Id,
        this.CardTypeName = value.Name
      this.CardTypeShow = false
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      for (let i in this.form) {
        this.form[i] = res[i]
      }
      this.CardTypeArr.forEach((item, index) => {
        if (item.Id === this.form.CardType) {
          this.defaultIndex = index
          this.CardTypeName = item.Name
          return
        }
      })
      if (!res.IsReal) {
        this.IsReal = false
      }
    },
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.ObverseImage = await userApi.upCaptchaFile(formdata)
    },
    async justAfterRead1(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.ReverseImage = await userApi.upCaptchaFile(formdata)
    },
    async handleSubmit() {
      if (this.ObverseImage === '') {
        this.$toast(this.$t('authentication').message)
        return false
      }
      if (this.ReverseImage === '') {
        this.$toast(this.$t('authentication').message)
        return false
      }
      this.form.IdCardImg = this.ObverseImage + ',' + this.ReverseImage
      let form = { ...this.form }
      for (let i in form) {
        if (form[i] === '') {
          this.$toast({
            message: this.$t('authentication').message,
            position: 'bottom',
          })
          return
        }
      }

      // 校验身份证号

      // let idCardRules = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      // if (!idCardRules.test(form.IdCard)) {
      //   this.$toast({
      //     message: this.$t('authentication').placeholder2,
      //     position: 'bottom',
      //   })
      //   return
      // }

      await userApi.realName(form)
      this.$toast(this.$t('authentication').success)
      this.$router.go(-1)
    },
  },
  mounted() {
    this.getUserInfo()
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/mixin.styl';

.container {
  width: 100%;
  background-color: #1f1f1f;
  font-size: 14px;
  color: #ebe5cd;
  overflow-x: hidden;
  height: 100vh;
  padding-top: 48.5px;

  p {
    color: #e60d0d;
    font-size: 13px;
    margin: 15px 0 10px 15px;
  }

  .van-field {
    background: none;

    >>> .van-field__label {
      color: #ebe5cd;
      margin-right: 0;
    }

    >>> .van-field__control {
      color: #fff;
    }

    >>> input::-webkit-input-placeholder {
      color: #969799;
    }
  }

  .bigBtn {
    width: 92%;
    height: 40px;
    flex-center();
    color: #222222;
    font-size: 15px;
    background: linear-gradient(315deg, #e8d49e, #d6b372) !important;
    margin: 30px auto;
    border-radius: 5px;
  }
}

.authTxt {
  font-size: 14px;
  color: #ebe5cd;
  padding: 10px 16px;
}

.img-box {
  padding: 0 16px;
}

.upImage {
  width: 120px;
}
</style>
